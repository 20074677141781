import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAxios } from "./useAxios";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import {
  ErrorMsg,
  UpdatePasswordPayload,
  UpdatePatient,
  UpdateTherapist,
  UserType,
  LoginData,
  PostLogin,
  GoogleProfileInfoParams,
  GoogleProfileInfo,
  ErrorMsgConfig,
  RegistrationAction,
  API_BY_USER_TYPE,
  EndpointGenerator,
  EmailValidationParams,
} from "@mapsy/shared";

export const useAuth = () => {
  const { postData, getData, patchData, ...rest } = useAxios();
  const { token } = useSelector(selectSessionState);

  const postLogin: (data: LoginData) => Promise<PostLogin> = useCallback(
    async (data) => {
      const loginEndpoint = EndpointGenerator.AuthAPI.login;
      return await postData(loginEndpoint, data, undefined, {
        404: ErrorMsg.InvalidCredentials,
      });
    },
    []
  );

  const openGoogleEndpoint = useCallback(() => {
    const loginEndpoint = EndpointGenerator.AuthAPI.googleLogin;
    window.open(loginEndpoint, "_self");
  }, []);

  const getGoogleProfile: ({
    token,
  }: GoogleProfileInfoParams) => Promise<GoogleProfileInfo> = useCallback(
    async (params) => {
      let errorConfig: ErrorMsgConfig | undefined = undefined;
      if (params.action === RegistrationAction.SignIn) {
        errorConfig = {
          404: ErrorMsg.UserNotFoundToLogin,
        };
      }

      if (params.action === RegistrationAction.SignUp) {
        errorConfig = {
          409: ErrorMsg.ExistingUser,
        };
      }

      const endpoint = EndpointGenerator.AuthAPI.googleLoginProfile;
      return await getData(endpoint, { params }, errorConfig);
    },
    []
  );

  const getProfileInfo = useCallback(async () => {
    const profileEndpoint = EndpointGenerator.AuthAPI.profile;
    return await getData(profileEndpoint, setToken(token));
  }, [token]);

  const getLogout = useCallback(async () => {
    const logoutEndpoint = EndpointGenerator.AuthAPI.logout;
    if (!token) {
      return;
    }
    return await getData(logoutEndpoint, setToken(token));
  }, [token]);

  const getUserInfo = useCallback(
    async (id: string, userType: UserType) => {
      const endpoint =
        EndpointGenerator[API_BY_USER_TYPE[userType]].urlById(id);
      return await getData(endpoint, setToken(token));
    },
    [token]
  );

  const updateUserInfo = useCallback(
    async (
      { _id, fieldsToUpdate }: UpdateTherapist | UpdatePatient,
      userType: UserType
    ): Promise<string> => {
      const endpoint =
        EndpointGenerator[API_BY_USER_TYPE[userType]].urlById(_id);
      return await patchData(endpoint, fieldsToUpdate, setToken(token));
    },
    [token]
  );

  const updatePassword = useCallback(
    async (
      payload: UpdatePasswordPayload,
      userType: UserType.Patient | UserType.Therapist
    ) => {
      const api =
        userType === UserType.Patient
          ? EndpointGenerator.PatientAPI
          : EndpointGenerator.TherapistAPI;
      const endpoint = api.changePasswordById(payload.id);
      return await patchData(endpoint, payload, setToken(token), {
        401: ErrorMsg.InvalidPassword,
      });
    },
    [token]
  );

  const emailValidation = useCallback(
    async ({ token, email, userType, tokenType }: EmailValidationParams) => {
      const endpoint = EndpointGenerator.TokenAPI.validateEmail(
        token,
        email,
        userType,
        tokenType
      );
      return await postData(endpoint);
    },
    []
  );

  const requestValidationEmail = useCallback(
    async ({ email, userType }: { email: string; userType: UserType }) => {
      const endpoint = EndpointGenerator.AuthAPI.validationEmail(
        email,
        userType
      );
      return await postData(endpoint, undefined, setToken(token), {
        400: ErrorMsg.EmailLinkMissingParams,
      });
    },
    [token]
  );

  return {
    ...rest,
    postLogin,
    getProfileInfo,
    getLogout,
    openGoogleEndpoint,
    getGoogleProfile,
    getUserInfo,
    updateUserInfo,
    updatePassword,
    emailValidation,
    requestValidationEmail,
  };
};
