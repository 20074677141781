import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { capitalize, Grid, Typography } from "@mui/material";

import { CustomButton } from "components/atoms/Button";
import COLORS from "constants/colors";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import { TitledLayout } from "layouts/TitledLayout";
import {
  Action,
  AppointmentStatus,
  CreateAppointment,
  ErrorMsg,
  Modality,
  ModalitySpanish,
  StylesEnum,
  UserType,
} from "@mapsy/shared";
import {
  ProvidedService,
  Therapist,
  ProvidedServiceTypesSpanish,
  Location,
  EndpointGenerator,
} from "@mapsy/shared";
import { selectSessionState } from "features/session/session.slice";
import { useAxios } from "hooks/useAxios";
import { setToken } from "utils/setToken";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { CustomLink } from "components/atoms/Link";

export const DEFAULT_APPOINTMENT_DURATION = 60;

export const AppointmentConfirmation = () => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const {
    state,
  }: {
    state: {
      location?: Location;
      hour?: number;
      service?: ProvidedService;
      therapist?: Therapist;
    } | null;
  } = useLocation();
  const { consultingActions } = useAppSelector(selectReasonState);
  const { postData, errorMsg, isLoading } = useAxios();
  const [isInvalidUser, setIsInvalidUser] = useState(false);

  useEffect(() => {
    const { predictedTopic } = consultingActions;
    if (
      !state?.location ||
      !state.hour ||
      !state.therapist ||
      state.service === undefined ||
      !predictedTopic
    ) {
      nav("/reason");
      return;
    }
  }, [state, consultingActions]);

  useEffect(() => {
    if (profileInfo?.type !== UserType.Patient) {
      setIsInvalidUser(true);
      return;
    }
    setIsInvalidUser(false);
  }, [profileInfo]);

  const info: { subtitle: string; data: string }[] = useMemo(() => {
    if (
      !state?.location ||
      !state.hour ||
      !state.therapist ||
      state.service === undefined
    ) {
      return [];
    }

    const { location, hour, service, therapist } = state;

    return [
      {
        subtitle: "Especialista",
        data: `${therapist?.firstName} ${therapist?.lastName}`,
      },
      {
        subtitle: "Terapia",
        data: ProvidedServiceTypesSpanish[service.serviceType],
      },
      {
        subtitle: "Fecha",
        data: capitalize(moment(hour).format(DATE_LONG_FORMAT)),
      },
      {
        subtitle: "Hora",
        data: `${moment(hour).format("HH:mm")} hrs`,
      },
      {
        subtitle: "Modalidad y precio",
        data: location
          ? `${ModalitySpanish[location.modality]} - $${service.price} ${service.currency.toLocaleLowerCase()}.`
          : "Consultorio no encontrado",
      },
    ];
  }, [state]);

  const handleContinue = useCallback(async () => {
    if (
      isInvalidUser ||
      errorMsg ||
      !state?.location ||
      !state.hour ||
      !state.therapist ||
      state.service === undefined
    ) {
      return;
    }

    const patientId = profileInfo?.id;

    if (!patientId) {
      nav("/users/signin?type=patient&redirectTo=/appointment/confirmation", {
        state,
      });
      return;
    }

    const { reason } = consultingActions;

    if (!token || !reason) {
      nav("/reason");
      return;
    }

    const { location, hour, service, therapist } = state;

    const createAppointment: CreateAppointment = {
      patientId,
      therapistId: therapist._id,
      locationId: location._id,
      date: hour,
      duration: DEFAULT_APPOINTMENT_DURATION,
      providedService: service,
      consultingReason: reason,
      appointmentStatus: AppointmentStatus.Pending,
    };

    const response: { _id: string } = await postData(
      EndpointGenerator.AppointmentAPI.baseURL,
      createAppointment,
      setToken(token),
      {
        409: ErrorMsg.AppointmentDuplicated,
        406: ErrorMsg.PatientRegistrationIncomplete,
      }
    );

    createAnalytic({
      action: Action.SUBMIT,
      componentName: COMPONENTS.APPOINTMENT_CONFIRMATION,
      data: createAppointment,
    });

    if (response?._id) {
      nav(`/patient/confirmed-appointment/${response._id}`);
    }
  }, [consultingActions, isInvalidUser, profileInfo, state, token, errorMsg]);

  const handleEditAppointment = useCallback(() => {
    if (!state?.therapist) {
      return;
    }
    const { therapist } = state;

    if (!therapist?._id) {
      return;
    }

    createAnalytic({
      action: Action.UNDO,
      componentName: COMPONENTS.APPOINTMENT_CONFIRMATION,
      data: { therapistId: therapist._id },
    });

    nav(`/therapists/${therapist?._id}`);
  }, [state]);

  if (!state?.location) {
    return (
      <TitledLayout
        title="Para confirmar una cita, busca un terapeuta y selecciona un horario"
        containerSx={{ alignItems: "center", maxWidth: "sm" }}
      >
        <Grid
          item
          md={12}
          lg={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 3,
            gap: 2,
          }}
        >
          <CustomLink underline to={"/reason"}>
            Buscar terapeuta
          </CustomLink>
        </Grid>
      </TitledLayout>
    );
  }

  const { location } = state;

  return (
    <TitledLayout
      title="Confirma Los Datos de Tu Cita"
      containerSx={{ alignItems: "center", maxWidth: "sm" }}
    >
      {info.map(({ subtitle, data }, i) => (
        <Grid item key={`${subtitle}-${i}`} xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            {subtitle}:
            <Typography component="span" sx={{ ml: 1 }}>
              {data}
            </Typography>
          </Typography>
        </Grid>
      ))}
      {location.modality !== Modality.Online && (
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Ubicación:
            <Typography component="span" sx={{ ml: 1 }}>
              {location
                ? `${location.community}, ${location.city}, ${location.state}`
                : "Ubicación del consultorio no encontrada"}
            </Typography>
          </Typography>
        </Grid>
      )}
      <Grid
        item
        md={12}
        lg={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mt: 3,
          gap: 2,
        }}
      >
        {isInvalidUser && (
          <Typography style={{ color: COLORS.TEXT_RED, fontWeight: 600 }}>
            Sólo los pacientes pueden agendar citas
          </Typography>
        )}
        <CustomButton
          customStyle={StylesEnum.primary}
          children={"Continuar"}
          onClick={() => handleContinue()}
          disableRipple={true}
          isLoading={isLoading}
          disabled={isInvalidUser || Boolean(errorMsg)}
          sx={{
            borderRadius: "14px",
          }}
          type="button"
        />
        {errorMsg && (
          <Typography style={{ color: COLORS.TEXT_RED, fontWeight: 600 }}>
            {errorMsg}
          </Typography>
        )}
        {errorMsg === ErrorMsg.PatientRegistrationIncomplete && (
          <CustomLink to="/patient/my_profile" underline>
            Ir a mi perfil
          </CustomLink>
        )}
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.8rem",
            color: COLORS.BLUE_1,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleEditAppointment}
        >
          Editar los datos de mi cita
        </Typography>
      </Grid>
    </TitledLayout>
  );
};
