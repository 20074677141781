import { useCallback, useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { CustomButton } from "components/atoms/Button";
import { startSession } from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useQuery } from "hooks/useQuery";
import { TitledLayout } from "layouts/TitledLayout";
import { ErrorMsg, StylesEnum } from "@mapsy/shared";
import { GoogleRedirectionAction, RegistrationAction } from "@mapsy/shared";
import { GOOGLE_REDIRECTION_ACTION, homePageByUserType } from "./SignInAndUp";
import { CustomLink } from "components/atoms/Link";

const GoogleOAuthSuccessRedirect = () => {
  const query = useQuery();
  const { getGoogleProfile, errorMsg, isLoading } = useAuth();
  const [userAction, setUserAction] = useState<GoogleRedirectionAction>();

  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const getSavedAction = useCallback(async () => {
    const googleRedirectionAction = localStorage.getItem(
      GOOGLE_REDIRECTION_ACTION
    );

    if (!googleRedirectionAction) {
      nav("/users/signin");
      return;
    }

    const { action, userType }: GoogleRedirectionAction = JSON.parse(
      googleRedirectionAction
    );

    if (action === undefined || userType === undefined) {
      nav("/users/signin");
      return;
    }

    setUserAction({ action, userType });
  }, []);

  useEffect(() => {
    const refreshToken = query.get("refreshToken");
    if (refreshToken) {
      getSavedAction();
    }
  }, []);

  const handleChangeUserAction = useCallback(
    async (token: string, userAction: GoogleRedirectionAction) => {
      const profileInfo = await getGoogleProfile({ token, ...userAction });

      if (!profileInfo?.login?.access_token) {
        return;
      }

      const { access_token } = profileInfo.login;
      dispatch(startSession({ access_token }));

      const { action, userType } = userAction;

      if (action === RegistrationAction.SignUp) {
        nav("/get-started");
        return;
      }

      nav(homePageByUserType[userType]);
    },
    []
  );

  useEffect(() => {
    const accessToken = query.get("accessToken");
    if (accessToken && userAction) {
      localStorage.removeItem(GOOGLE_REDIRECTION_ACTION);
      handleChangeUserAction(accessToken, userAction);
    }
  }, [userAction]);

  if (errorMsg === ErrorMsg.UserNotFoundToLogin && userAction) {
    return (
      <TitledLayout title={errorMsg}>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            onClick={() =>
              setUserAction((_userAction) => ({
                ...userAction,
                action: RegistrationAction.SignUp,
              }))
            }
            customStyle={StylesEnum.primary}
            children={"Registrarme"}
            isLoading={isLoading}
            disableRipple={true}
            sx={{
              borderRadius: "14px",
            }}
          />
        </Grid>
      </TitledLayout>
    );
  }

  if (errorMsg === ErrorMsg.ExistingUser && userAction) {
    return (
      <TitledLayout title={errorMsg}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Grid item md={12}>
            <Typography sx={{ textAlign: "center" }}>
              Puedes iniciar sesión ahora.
            </Typography>
          </Grid>
          <CustomButton
            onClick={() =>
              setUserAction((_userAction) => ({
                ...userAction,
                action: RegistrationAction.SignIn,
              }))
            }
            customStyle={StylesEnum.primary}
            children={"Iniciar Sesión"}
            disableRipple={true}
            isLoading={isLoading}
            sx={{
              borderRadius: "14px",
            }}
          />
        </Grid>
      </TitledLayout>
    );
  }

  return (
    <TitledLayout title="Espera a ser redireccionado...">
      <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Grid>
      <Grid item md={12}>
        <Typography sx={{ textAlign: "center" }}>
          Si este proceso tarda demasiado, haz{" "}
          <CustomLink to="/users/signin" underline>click aquí</CustomLink>
        </Typography>
      </Grid>
    </TitledLayout>
  );
};

export default GoogleOAuthSuccessRedirect;
