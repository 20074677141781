export enum ErrorMsg {
  Default = "¡Algo salió mal 😭! Por favor, contacta a soporte técnico.",
  Authentication = "Inicia sesión para realizar esta acción.",
  Forbidden = "No tienes permisos para realizar esta acción.",
  NotFound = "Registro no encontrado.",
  UserNotFoundToLogin = "No es posible iniciar sesión porque aún no completas tu registro.",
  InternalServer = "Los servicios no están disponibles. Contacta a soporte.",
  IsRequired = "Este campo es obligatorio.",
  FormatNotValid = "Formato no válido.",
  NumbersNotAllowed = "Formato no válido. No se permiten números.",
  NumberLowerThanMin = "El número es muy bajo.",
  NumberHigherThanMin = "El número es muy alto.",
  PhoneFormatNotValid = "Formato de teléfono no válido. Se requieren 10 dígitos.",
  SmallerThanMinLength = "El texto es demasiado corto.",
  LargetThanMaxLength = "El texto es demasiado largo.",
  InvalidConsultingReason = "Para una recomendación más precisa, por favor, bríndanos más información acerca de tu motivo de consulta.",
  ExistingUser = "Encontramos una cuenta previamente guardada en Mapsy.",
  InvalidPassword = "Credenciales inválidas. Verifica tu correo y contraseña.",
  InvalidCredentials = "Credenciales inválidas. Verifica tu correo y contraseña.",
  PasswordsMustBeDifferent = "La nueva contraseña debe ser diferente a la anterior.",
  NewPasswordsAreDifferent = "Las contraseñas no coinciden.",
  AppointmentDuplicated = "Alguien ha agendado una cita con este terapeuta para el horario que elegiste. Intenta con otro horario.",
  EmailAlreadyInUse = "Ya existe una cuenta con este email.",
  RegistrationGeneric = "No se ha podido crear el registro.",
  AccountGeneric = "No se ha podido crear la cuenta.",
  ErrorGeneric = "Ha ocurrido un error. Inténtalo más tarde.",
  LowDate = "La fecha debe ser mayor.",
  CommentDuplicated = "Ya has comentado a este terapeuta. Solo puedes comentar una vez por terapeuta.",
  TryAnotherEmail = "No se puede crear la cuenta. Intenta con otro correo.",
  FileNotUpload = "El archivo no se pudo guardar. Intenta con otro.",
  ProfilePicStatusNotUpdated = "No se pudo actualizar tu perfil. Contacta a soporte.",
  PatientRegistrationIncomplete = "Para poder agendar cita, completa tu perfil y verifica tu correo.",
  EmailLinkMissingParams="Faltan parámetros. Da click de nuevo desde el correo que recibiste."
}
