import { WorkDay } from ".";
import { Modality, Parent } from "../enum";
import { Currency, Theories } from "../enum";
import {
  AccountStatus,
  CommonUserInfo,
  Filters,
  GetAllQuery,
  ImageURL,
  Timestamps,
  UpdateUser,
} from "./shared.inteface";

export type Therapist = {
  _id: string;
  stars: 0 | 1 | 2 | 3 | 4 | 5;
} & Omit<
  CreateTherapist,
  "locations" | "schoolGrades" | "professionalExperience" | "accountStatus"
> &
  Partial<Pick<CreateTherapist, "password">> &
  Partial<Pick<CreateTherapist, "phone">> & {
    locations: Location[];
    schoolGrades: SchoolGrade[];
    professionalExperience: ProfessionalExperience[];
    accountStatus: AccountStatus;
  } & Timestamps &
  ImageURL;

export interface SchoolGrade {
  level?: SchoolGradeLevel;
  institution: string;
  year: number | string;
  major: string;
}

export interface ProfessionalExperience {
  institution: string;
  startYear: number;
  endYear: number;
  position: string;
}

export enum SchoolGradeLevel {
  Lic = "Lic",
  Master = "Master",
  Phd = "Phd",
  PosPhd = "PosPhd",
  Specialist = "Specialist",
  Certification = "Certification",
}

export enum ProvidedServiceTypes {
  Individual,
  Couple,
  Family,
  Groupal,
}

export enum ProvidedServiceTypesSpanish {
  "Individual",
  "De Pareja",
  "Familiar",
  "Grupal",
}

export interface ProvidedService {
  _id: string;
  serviceType: ProvidedServiceTypes;
  price: number;
  currency: keyof typeof Currency;
}

export interface Address {
  street: string;
  externalNo: string;
  internalNo?: string;
  cp: string;
  community: string;
  city: string;
  state: string;
  country: string;
}
export interface Location extends Address {
  _id: string;
  name: string;
  workSchedule: WorkSchedule;
  providedServices: ProvidedService[];
  modality: Modality;
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type DefaultLocation = DeepPartial<Location>;

export interface WorkSchedule {
  monday: WorkDay;
  tuesday: WorkDay;
  wednesday: WorkDay;
  thursday: WorkDay;
  friday: WorkDay;
  saturday: WorkDay;
  sunday: WorkDay;
}

export type CreateTherapist = CommonUserInfo & {
  professionalId: string;
  theory: Theories;
  credentials: string;
  verifiedPassword?: string;
  topicsId: string[];
  locations: DefaultLocation[];
  schoolGrades: Partial<SchoolGrade>[];
  professionalExperience: Partial<ProfessionalExperience>[];
  accountStatus?: Partial<AccountStatus>;
};

export interface UpdateTherapist extends UpdateUser<Therapist> {}

export enum WeekDaysSpanish {
  monday = "lunes",
  tuesday = "martes",
  wednesday = "miércoles",
  thursday = "jueves",
  friday = "viernes",
  saturday = "sábado",
  sunday = "domingo",
}

export interface ProfessionalIdSEPAPIResponse {
  items: SEPApiProfessionalIdItem[];
  filename: string | null;
  idCedula: string | null;
  idProfesionista: string | null;
  sessionId: string | null;
  theStream: string | null;
  token: string | null;
  urlVideo: string | null;
}

export interface SEPApiProfessionalIdItem {
  anioreg: number;
  carArea: string | null;
  carCons: string | null;
  carNivel: string | null;
  carSarea: string | null;
  curp: string | null;
  desins: string;
  foja: string | null;
  idCedula: string;
  inscons: number;
  insedo: number;
  libro: string | null;
  materno: string;
  maternoM: string | null;
  nombre: string;
  nombreM: string | null;
  numero: string | null;
  paterno: string;
  paternoM: string | null;
  sexo: string;
  tipo: string;
  titulo: string;
}

export type GetAllTherapistByTopicParent = GetAllQuery<Therapist> & {
  parent: Parent;
};

export type GetAllTherapistByTopicSiblings = {
  params: Required<GetAllQuery<Therapist>>;
  topicId: string;
};

export type GetAllTherapists = GetAllQuery<Therapist> & {
  filters?: Filters<Therapist>;
} & (
    | {
        topicId: string;
        getAllTherapists?: boolean;
      }
    | {
        topicId?: string;
        getAllTherapists: boolean;
      }
  );

export interface ProfessionalIdValidation {
  firstName: string;
  professionalId: string;
  lastName: string;
  updated: boolean;
  isNameValid: boolean;
  isMajorValid: boolean;
  email: string;
  externalApiResponse: {
    sepResponse?: SEPApiProfessionalIdItem;
  };
}
